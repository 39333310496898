import { PageTypes, Theme, ThemeContext } from "assets";
import { Card, FormattedText } from "atoms";
import { graphql } from "gatsby";
import {
  convertBreadcrumbNavigation,
  convertDirectionNavigation,
  convertHero,
} from "molecules";
import { convertComponentList, PageLayout } from "organisms";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./story.module.scss";

const StoryPage = ({ data }) => {
  const storyData = data?.craftAPI?.entry;

  const children = convertComponentList(storyData?.componentList);

  storyData.tags = [];
  storyData.topicTag?.map((tag) => {
    storyData.tags.push(tag.title);
  });
  storyData.tags.push(storyData.timePeriod[0]?.title);
  // Place the hero at the top of the page
  children.unshift(convertHero(storyData));

  const siblings = storyData.parent?.children.filter(
    (item) => item.id !== storyData.id
  );

  // find stories with the most related tags
  const findRelatedTags = (sibling) => {
    const siblingTags = sibling.topicTag;
    const storyTags = storyData.tags;
    return siblingTags?.filter((tag) => storyTags?.includes(tag.title));
  };

  const assignCardData = (cardData) => {
    const displayTag = [
      cardData.relatedTags[
        Math.floor(Math.random() * cardData.relatedTags.length)
      ].title,
    ];

    if (cardData.children) {
      const pageLink = {
        id: cardData.children[0]?.id,
        uri: cardData.children[0]?.uri,
      };
      return (
        <Card
          caption={
            cardData.shortDescription || cardData.children[0]?.shortDescription
          }
          image={
            cardData.coverImage?.[0] || cardData.children[0]?.coverImage?.[0]
          }
          imageObjectFit={
            cardData.coverImage?.[0] ? cardData.coverImageCropStyle : null
          }
          imageObjectPosition={
            cardData.coverImage?.[0]
              ? cardData.coverImagePositionOverride
              : null
          }
          key={cardData.id}
          pageLink={pageLink}
          storyType={cardData.storyType}
          tags={displayTag}
          theme={Theme.Black}
          title={cardData.title}
          horizontalLayout
          showTags
        />
      );
    } else {
      const pageLink = { id: cardData.id, uri: cardData.uri };
      return (
        <Card
          caption={cardData.shortDescription}
          image={cardData.coverImage?.[0]}
          imageObjectFit={cardData.coverImageCropStyle}
          imageObjectPosition={cardData.coverImagePositionOverride}
          key={cardData.id}
          pageLink={pageLink}
          storyType={cardData.storyType}
          tags={displayTag}
          theme={Theme.Black}
          title={cardData.title}
          horizontalLayout
          showTags
        />
      );
    }
  };

  const getRelatedStoriesByTags = () => {
    siblings?.forEach(
      (sibling) => (sibling.relatedTags = findRelatedTags(sibling))
    );
    const storiesByNumRelatedTags = [];
    siblings
      ?.filter((sibling) => sibling.relatedTags?.length > 0)
      .forEach((sibling) => {
        const numRelatedTags = sibling.relatedTags?.length;
        if (!storiesByNumRelatedTags[numRelatedTags])
          storiesByNumRelatedTags[numRelatedTags] = [];
        storiesByNumRelatedTags[numRelatedTags].push(sibling);
      });

    let relatedStories = [];
    storiesByNumRelatedTags?.reverse().forEach((stories) => {
      const numStoriesNeeded = 3 - relatedStories.length;
      if (stories.length <= numStoriesNeeded) {
        relatedStories.push(...stories);
      } else if (numStoriesNeeded > 0) {
        let storiesNeeded = stories
          .sort(() => 0.5 - Math.random())
          .slice(0, numStoriesNeeded);
        relatedStories.push(...storiesNeeded);
      }
    });

    const cards = relatedStories?.map((cardData) => assignCardData(cardData));

    if (relatedStories.length) {
      return (
        <div className={`container-fluid ${[styles.relatedStories]}`}>
          <FormattedText
            outerElement={<h2 />}
            text={"Related Stories"}
            deepLink
          />
          {cards}
        </div>
      );
    }
  };

  children.unshift(
    convertBreadcrumbNavigation({
      pageTitle: storyData.title,
      parents: storyData.parent,
    })
  );
  children.push(getRelatedStoriesByTags());
  children.push(convertDirectionNavigation(storyData));

  return (
    <ThemeContext.Provider
      value={{
        theme: Theme.Black,
        fontType: "",
      }}
    >
      <PageLayout
        coverImage={storyData.coverImage}
        pageType={PageTypes.STORY}
        shortDescription={storyData.shortDescription}
        theme={Theme.Black}
        title={storyData.title}
      >
        {children}
      </PageLayout>
    </ThemeContext.Provider>
  );
};

StoryPage.propTypes = {
  data: PropTypes.shape({
    craftAPI: PropTypes.shape({
      entry: PropTypes.shape({}),
    }),
  }),
};

export const storiesQuery = graphql`
  query (
    $uri: [String]
    $section: [String] = "story"
    $type: [String] = ["ptpTopic", "story", "storiesLanding"]
  ) {
    craftAPI {
      entry(uri: $uri) {
        ...BreadcrumbNavigationFragment
        ... on CraftAPI_story_story_Entry {
          id
          componentList {
            ...ComponentListFragment
          }
          coverImageCropStyle
          coverImagePositionOverride
          coverImage {
            ...ImageMetadataFragment
          }
          longDescription
          shortDescription
          heroType
          storyType(label: true)
          topicTag {
            title
          }
          timePeriod {
            title
          }
          title
          ...TypedDirectionNavigationFragment
          parent {
            ... on CraftAPI_story_storiesLanding_Entry {
              children {
                ... on CraftAPI_story_story_Entry {
                  id
                  coverImageCropStyle
                  coverImagePositionOverride
                  coverImage {
                    ...ImageMetadataFragment
                  }
                  shortDescription
                  storyType(label: true)
                  topicTag {
                    title
                  }
                  title
                  uri
                }
                ... on CraftAPI_story_ptpTopic_Entry {
                  id
                  storyType(label: true)
                  topicTag {
                    title
                  }
                  title
                  coverImage {
                    ...ImageMetadataFragment
                  }
                  shortDescription
                  children(limit: 1) {
                    ... on CraftAPI_story_ptpEntry_Entry {
                      id
                      title
                      shortDescription
                      coverImage {
                        ...ImageMetadataFragment
                      }
                      uri
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default StoryPage;
